import client from './api-client';
import { LS_TOKEN } from '../constants';

const localStorageKey = LS_TOKEN;

const login = async ({ login, password }) => {
  try {
    const response = await client('auth/login', {
      method: 'POST',
      data: {
        login,
        password
      }
    });
    window.localStorage.setItem(localStorageKey, Math.random().toString(36).substr(2, 12));
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

const logout = async () => {
  try {
    await client('auth/logout', {
      method: 'GET'
    });
  } catch (e) {
    console.log(e);
  } finally {
    window.localStorage.removeItem(localStorageKey);
  }
}

const getToken = () => {
  return window.localStorage.getItem(localStorageKey);
}

const isLoggedIn = () => {
  return Boolean(getToken());
}

export { login, logout, getToken, isLoggedIn };
