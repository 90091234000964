import React from 'react';
import { CustomThemeProvider } from './theme-context';
import { AuthProvider } from './auth-context';

const AppProviders = ({ children }) => {
  return (
    <CustomThemeProvider>
      <AuthProvider>
        { children }
      </AuthProvider>
    </CustomThemeProvider>
  );
};

export { AppProviders };
