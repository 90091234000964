import { APP_API_URL } from '../constants';
import axios from 'axios';
import { LS_TOKEN } from '../constants';

const CancelToken = axios.CancelToken;
let cancel;

const localStorageKey = LS_TOKEN;

const http = axios.create({
  baseURL: APP_API_URL
});

http.interceptors.response.use(function (response) {
  return response;
}, async (error) => {
  if (error?.response?.status === 401) {
    window.localStorage.removeItem(localStorageKey);
    window.location.href = '/login';
  }
  return Promise.reject(error);
});

const client = async (endpoint, { method, body, ...customConfig } = {}) => {
  if (cancel !== undefined) {
    cancel();
  }

  const headers = {
    'content-type': 'application/json'
  };

  const config = {
    url: endpoint,
    method: method ? method : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    withCredentials: true,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  };

  const response = await http(config);

  return response;
}

export default client;
