import React, { useLayoutEffect, useContext, createContext, useState } from 'react';
import { useAsync } from 'react-async';
import * as authClient from '../utils/auth-client';
import Spinner from '../components/Spinner';
import { bootstrapAppData } from '../utils/bootstrap';

const AuthContext = createContext();

const AuthProvider = props => {
  const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);
  const {
    data = { logged: false },
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: bootstrapAppData
  });

  useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <Spinner />;
    }
    if (isRejected) {
      return (
        <div style={{ color: 'red' }}>
          <p>Uh oh... There's a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      )
    }
  }

  const login  = form => authClient.login(form).then(reload);
  const logout = () => authClient.logout().then(reload);

  return (
    <AuthContext.Provider value={{ data, login, logout }} {...props} />
  )
}

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context;
}

export { AuthProvider, useAuth };
