import * as authClient from './auth-client';

const bootstrapAppData = async () => {
  let appData = { logged: false };

  if (authClient.isLoggedIn()) {
    appData = { logged: true };
    // Maybe get data?
  }

  return appData;
}

export { bootstrapAppData };